import { Component, OnInit } from '@angular/core';

import { PageEvent } from '@angular/material/paginator';
import { PayoutsService } from '../../../../services/payouts/payouts.service';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import * as saveAs from 'file-saver';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { getViewValue } from 'src/assets/common/functions/functions';
import { FilterService } from 'src/app/services/filter/filter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payouts-transaction-reports',
  templateUrl: './payouts-transaction-reports.component.html',
  styleUrls: ['./payouts-transaction-reports.component.scss'],
})
export class PayoutsTransactionReportsComponent {
  public rows: any = [];
  public CSVSeparatorModal: boolean = false;
  public payoutStartDateValue: string = '';
  public payoutEndDateValue: string = '';
  public referenceId: string = '';
  public search: string = '';
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public disabledWhileRequest: boolean = false;
  public selectedStatus: string = '';
  public statusList: Array<string> = [
    'ALL',
    'PROCESSING',
    'COMPLETED',
    'ERROR',
    'CREATED',
  ];

  public displayedColumns: string[] = [
    'id',
    'referenceId',
    'payoutDate',
    'asset',
    'amount',
    'beneficiary',
    'status',
  ];
  public dataSource = [];
  public filterParams = {};
  public isLoading: boolean = false;
  public selectedSeparator: string = '';
  public separatorOptions: Array<any> = [];
  private cacheToken = sessionStorage;

  constructor(
    private payoutsService: PayoutsService,
    private paymentsService: PaymentsService,
    private reportsService: ReportsService,
    private filterService: FilterService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const savedFilters = this.filterService.getFilters(
      'transactionPageFilters'
    );

    this.payoutStartDateValue =
      savedFilters.startDate || this.payoutStartDateValue;
    this.payoutEndDateValue = savedFilters.endDate || this.payoutEndDateValue;
    this.selectedStatus = savedFilters.selectedStatus || '';
    this.search = savedFilters.search || '';

    this.filterTable();

    this.reportsService.getSeparatorTypes().subscribe(({ content }) => {
      this.separatorOptions = content.map((value: string) => {
        return {
          value: value,
          viewValue: getViewValue(value),
        };
      });
      this.selectedSeparator = this.cacheToken.getItem('userSeparator') || '';
    });

    this.filterService.resetFilters('transactionPageFilters');
  }

  public saveFiltersAndNavigate(tx_id: string): void {
    const filters = {
      startDate: this.payoutStartDateValue,
      endDate: this.payoutEndDateValue,
      selectedStatus: this.selectedStatus,
      search: this.search,
    };

    this.filterService.setFilters('transactionPageFilters', filters);

    this.router.navigate(['/payouts/reports/transaction-reports/details'], {
      queryParams: { id: tx_id },
    });
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public filterTable(page: number = 0) {
    this.isLoading = true;
    this.dataSource = [];
    const formattedStartDate = this.paymentsService.formatDate(
      this.payoutStartDateValue
    );
    const formattedEndDate = this.paymentsService.formatDate(
      this.payoutEndDateValue
    );

    this.payoutsService
      .getPayouts(
        this.referenceId,
        formattedStartDate,
        formattedEndDate,
        this.selectedStatus === 'ALL' ? '' : this.selectedStatus,
        this.pageSize,
        page
      )
      .subscribe((response) => {
        this.dataSource = response.content;
        this.selector = response.totalElements;
        this.isLoading = false;
      });
  }

  public downloadCSV() {
    const formattedStartDate = this.paymentsService.formatDate(
      this.payoutStartDateValue
    );
    const formattedEndDate = this.paymentsService.formatDate(
      this.payoutEndDateValue
    );

    this.payoutsService
      .getPayoutsCSV(
        formattedStartDate,
        formattedEndDate,
        this.selectedStatus === 'ALL' ? '' : this.selectedStatus,
        this.selectedSeparator
      )
      .subscribe(
        (csvData: string) => {
          const blob = new Blob([csvData], { type: 'text/csv' });
          this.cacheToken.setItem('userSeparator', this.selectedSeparator);
          saveAs(blob, 'data.csv');
        },
        (error) => {
          console.error('Error downloading CSV:', error);
          alert('Error downloading CSV file');
        }
      );
  }

  public toggleCSVModal() {
    this.CSVSeparatorModal = !this.CSVSeparatorModal;
  }
}
