import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../services/payments/payments.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnInit {
  public transactionId: string = '';
  public refundList: any = [
    { label: 'Token', value: 'paymentToken' },
    { label: 'Reference', value: 'reference' },
    { label: 'Method', value: 'method' },
    { label: 'Amount', value: 'amount' },
    { label: 'Customer Amount', value: 'customerAmount' },
    { label: 'Status', value: 'transactionStatus' },
  ];
  public refundInfo: any = null;
  public amountToRefund: number = 0;
  public paymentIsRefundable: boolean = true;
  public paymentRefunded: boolean = false;
  public isLoading: boolean = false;
  public isOxxoSpei: boolean = false;
  public isBoleto: boolean = false;
  public refundData: any = {};
  private paymentToken: string = '';

  constructor(
    private paymentsService: PaymentsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.transactionId = this.activatedRoute.snapshot.queryParams['id'];

    if (this.transactionId) {
      this.checkAvailableRefund();
    }
  }

  public checkAvailableRefund() {
    this.isOxxoSpei = false;
    this.paymentsService
      .getRefundAvailable(this.transactionId)
      .pipe(
        finalize(() => {
          this.isBoleto = false;
          this.isOxxoSpei = false;
          if (
            this.refundInfo &&
            this.refundInfo.method === ('OXXO' || 'SPEI')
          ) {
            this.isOxxoSpei = true;
          }
          if (this.refundInfo && this.refundInfo.method === 'BOLETO') {
            this.isBoleto = true;
          }
        })
      )
      .subscribe({
        next: (response) => {
          this.refundInfo = response;
          this.paymentIsRefundable = true;
          this.paymentToken = response.paymentToken;
          this.amountToRefund = response.amount;
        },
        error: (e) => {
          this.paymentIsRefundable = false;
        },
      });
  }

  public refundTransaction() {
    this.isLoading = true;

    this.paymentsService
      .postRefund(this.paymentToken, this.amountToRefund, this.refundData)
      .subscribe({
        next: () => {
          this.paymentRefunded = true;
          this.isLoading = false;
        },
        error: (e) => {
          this.isLoading = false;
          alert('Error while refunding');
        },
      });
  }
}
