import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthInterceptor } from './services/HttpInterceptor';
import { AuthGuard } from './guards/auth.guard';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { DefaultComponent } from './layouts/default/default.component';
import { DeclinesComponent } from './components/payments/declines/declines.component';
import { RevenuesComponent } from './components/payments/revenues/revenues.component';
import { SettlementsComponent } from './components/payments/settlements/settlements.component';
import { RefundComponent } from './components/refund/refund.component';
import { BusinessInfoComponent } from './components/merchant/business-info/business-info.component';
import { BankAccountsComponent } from './components/merchant/bank-accounts/bank-accounts.component';
import { ProjectsComponent } from './components/payments/projects/projects.component';
import { MerchantAgreementComponent } from './components/legal/merchant-agreement/merchant-agreement.component';
import { TCsComponent } from './components/legal/tcs/tcs.component';
import { HeaderComponent } from './components/header/header.component';
import { TransactionReportsComponent } from './components/payments/transaction-reports/transaction-reports.component';
import { ServiceSettlementsComponent } from './components/payments/service-settlements/service-settlements.component';
import { TransfersComponent } from './components/payments/transfers/transfers.component';
import { FirewallRulesComponent } from './components/payments/firewall-rules/firewall-rules.component';
import { CardComponent } from './components/card/card.component';
import { LastDaysCardComponent } from './components/last-days-card/last-days-card.component';
import { IndicesComponent } from './components/dashboard/components/indices/indices.component';
import { PercentageVariationCardComponent } from './components/percentage-variation-card/percentage-variation-card.component';
import { ArrowIconComponent } from '../assets/common/icons/arrow-icon/arrow-icon.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { SettlementsTableComponent } from './components/settlements-table/settlements-table.component';
import { MatTableModule } from '@angular/material/table';
import { LineChartComponent } from './components/dashboard/components/line-chart/line-chart.component';
import { BalancesComponent } from './components/dashboard/balances/balances.component';
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { FormatThousandPipe } from './pipes/format-thousand.pipe';
import { DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { IdTransactionReportsComponent } from './components/id-validation/id-transaction-reports/id-transaction-reports.component';
import { IdServiceStatementsComponent } from './components/id-validation/id-service-statements/id-service-statements.component';
import { IdProjectsComponent } from './components/id-validation/projects/id-projects.component';
import { LogsComponent } from './components/logs/logs.component';
import { ApiKeysComponent } from './components/merchant/api-keys/api-keys.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { StatusCardComponent } from './components/status-card/status-card.component';
import { BlockModalComponent } from './components/firewall-rules/block-modal/block-modal.component';
import { UnblockModalComponent } from './components/firewall-rules/unblock-modal/unblock-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModalComponent } from './components/modal/modal.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ReblockModalComponent } from './components/firewall-rules/reblock-modal/reblock-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PayoutsTransactionReportsComponent } from './components/payouts/reports/payouts-transaction-reports/payouts-transaction-reports.component';
import { PayoutsProjectsComponent } from './components/payouts/payouts-projects/payouts-projects.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CurrencyModalComponent } from './components/currency-modal/currency-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { LoaderDirective } from './directives/loader.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { StatementsDetailComponent } from './components/id-validation/statements-detail/statements-detail.component';
import { PendingStatusCardComponent } from './components/pending-status-card/pending-status-card.component';
import { FormatCurrencyFromToPipe } from './pipes/format-currency-from-to.pipe';
import { ConvertCurrencyPipe } from './pipes/convert-currency.pipe';
import { RefundsReportComponent } from './components/payments/refunds-report/refunds-report.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { FormatTextPipe } from './pipes/format-text.pipe';
import { DisputesComponent } from './components/disputes/disputes.component';
import { DisputeDetailsComponent } from './components/disputes/dispute-details/dispute-details.component';
import { DisputeStatusCardComponent } from './components/dispute-status-card/dispute-status-card.component';
import { SplitUnderscorePipe } from './pipes/split-underscore.pipe';
import { BalanceComponent } from './components/payouts/balance/balance.component';
import { PayoutsTransactionDetailsComponent } from './components/payouts/payouts-transaction-details/payouts-transaction-details.component';
import { DownloadCsvButtonComponent } from './components/download-csv-button/download-csv-button.component';
import { FieldsetComponent } from './components/fieldset/fieldset.component';
import { UTCDateAdapter } from './utils/utc-date-adapter';
import { APP_DATE_FORMATS } from './utils/app-date-formats';

@NgModule({
  declarations: [
    AppComponent,
    BarChartComponent,
    LoginComponent,
    DashboardComponent,
    SideBarComponent,
    EmptyLayoutComponent,
    DefaultComponent,
    DeclinesComponent,
    RevenuesComponent,
    SettlementsComponent,
    RefundComponent,
    BusinessInfoComponent,
    BankAccountsComponent,
    ProjectsComponent,
    MerchantAgreementComponent,
    TCsComponent,
    HeaderComponent,
    TransactionReportsComponent,
    ServiceSettlementsComponent,
    TransfersComponent,
    FirewallRulesComponent,
    CardComponent,
    LastDaysCardComponent,
    IndicesComponent,
    PercentageVariationCardComponent,
    ArrowIconComponent,
    DonutChartComponent,
    SettlementsTableComponent,
    LineChartComponent,
    BalancesComponent,
    FormatCurrencyPipe,
    FormatThousandPipe,
    TransactionDetailComponent,
    IdTransactionReportsComponent,
    IdServiceStatementsComponent,
    IdProjectsComponent,
    LogsComponent,
    ApiKeysComponent,
    StatusCardComponent,
    BlockModalComponent,
    UnblockModalComponent,
    ModalComponent,
    SearchBarComponent,
    ReblockModalComponent,
    PayoutsTransactionReportsComponent,
    PayoutsProjectsComponent,
    ProfileComponent,
    CurrencyModalComponent,
    LoaderDirective,
    LoaderComponent,
    StatementsDetailComponent,
    PendingStatusCardComponent,
    FormatCurrencyFromToPipe,
    ConvertCurrencyPipe,
    RefundsReportComponent,
    SplitCamelCasePipe,
    FormatTextPipe,
    DisputesComponent,
    DisputeDetailsComponent,
    DisputeStatusCardComponent,
    SplitUnderscorePipe,
    BalanceComponent,
    PayoutsTransactionDetailsComponent,
    DownloadCsvButtonComponent,
    FieldsetComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgChartsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCheckboxModule,
    SimplebarAngularModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    NgxCaptchaModule,
    MatTooltipModule,
  ],
  exports: [LoaderDirective],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DateAdapter, useClass: UTCDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    AuthGuard,
    DatePipe,
    ConvertCurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
