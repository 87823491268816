import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class UTCDateAdapter extends NativeDateAdapter {
  override createDate(year: number, month: number, date: number): Date {
    // Cria uma data em UTC
    const result = new Date(Date.UTC(year, month, date));
    return result;
  }

  override parse(value: any): Date | null {
    // Analisa a string de data no formato yyyy-MM-dd
    if (typeof value === 'string' && value.length > 0) {
      const str = value.trim();
      const parts = str.split('-');
      if (parts.length === 3) {
        const year = +parts[0];
        const month = +parts[1] - 1; // meses começam em zero
        const day = +parts[2];
        return new Date(Date.UTC(year, month, day));
      }
    }
    return super.parse(value);
  }

  override format(date: Date, displayFormat: Object): string {
    // Formata a data em UTC no formato yyyy-MM-dd
    if (date) {
      const year = date.getUTCFullYear();
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
      const day = ('0' + date.getUTCDate()).slice(-2);
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  override getYear(date: Date): number {
    return date.getUTCFullYear();
  }

  override getMonth(date: Date): number {
    return date.getUTCMonth();
  }

  override getDate(date: Date): number {
    return date.getUTCDate();
  }
}
