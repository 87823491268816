import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from './auth/auth-service.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private cancelRequests$ = new Subject<void>();

  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      req = this.addTokenToRequest(req, token);
    }

    return next.handle(req).pipe(
      takeUntil(this.cancelRequests$),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.url && event.url.includes('auth')) {
            if (event.body && event.body.token) {
              this.authService.setToken(event.body.token);
              req = this.addTokenToRequest(req, event.body.token);
            }
          } else if (
            event.body &&
            (event.body.refresh_token || event.body.token)
          ) {
            this.authService.setToken(
              event.body.refresh_token || event.body.token
            );
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 200) {
          this.showMessageAlert(error.error?.text);
          return throwError(error);
        }
        if (error.status === 401) {
          this.cancelAllRequests();
          this.showTokenAlert();
          return throwError('Failed to get token.');
        } else {
          this.showAlert(
            error.status,
            error.error?.message || error.statusText
          );
          if (error.error?.refresh_token) {
            this.authService.setToken(error.error.refresh_token);
          }
          return throwError(error);
        }
      })
    );
  }

  private showTokenAlert(): Promise<void> {
    this.authService.deleteToken();
    return Swal.fire({
      title: 'Error',
      text: 'Token Expired',
      icon: 'warning',
      confirmButtonText: 'Ok',
      reverseButtons: true,
    }).then(() => {
      this.router.navigate(['']);
    });
  }

  private showMessageAlert(message?: string): Promise<void> {
    if (!message) {
      return Promise.resolve();
    }

    const [signaturesPart] = message.includes('cannot be deleted!')
      ? message.split('cannot be deleted!')
      : [message];

    const signatures = signaturesPart.split(',').map((sig) => sig.trim());

    const htmlContent = `
      <p>The following signatures can't be deleted:</p>
      <ul>
        ${signatures.map((sig) => `<li>${sig}</li>`).join('')}
      </ul>
    `;

    return Swal.fire({
      html: htmlContent,
      icon: 'error',
      confirmButtonText: 'Ok',
      reverseButtons: true,
    }).then();
  }

  private cancelAllRequests(): void {
    this.cancelRequests$.next();
  }

  private showAlert(
    errorCode: number,
    errorDescription: string
  ): Promise<void> {
    return Swal.fire({
      title: errorCode.toString(),
      text: errorDescription,
      icon: 'warning',
      confirmButtonText: 'Ok',
      reverseButtons: true,
    }).then();
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'X-Auth-Token': token,
      },
    });
  }
}
