import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SideBarService } from '../../services/side-bar/side-bar.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth-service.service';
import { ProjectsService } from '../../services/projects/projects.service';

export interface MenuItem {
  label: string;
  link?: string;
  externalLink?: string;
  external?: Boolean;
  subMenu?: MenuItem[];
  isVisible?: Boolean;
  showSandboxProd?: Boolean;
}

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  host: {
    '[class.skin-letpay]': 'theme === "skin-letpay"',
    '[class.skin-epag]': 'theme === "skin-epag"',
  },
})
export class SideBarComponent implements OnInit {
  public basePath: string;
  public logoWidth: number;
  public theme: string;
  public minimizedPath: string = 'logos/logo.svg';
  public selectedMenuItem: MenuItem = {
    label: 'Dashboard',
    link: '/dashboard',
  };
  public selectedSubMenuItem: MenuItem | null = null;
  public selectedNestedSubMenuItem: MenuItem | null = null;
  public selectedPath: string[] = [];
  public submenuMaxHeight: number = 0;
  public nestedMenuMaxHeight: number = 0;
  public openPersonalInfo: boolean = false;
  public openCurrency: boolean = false;

  public menuItems: MenuItem[] = [
    { label: 'Dashboard', link: '/dashboard' },
    {
      label: 'Payments',
      subMenu: [
        {
          label: 'Reports',
          subMenu: [
            {
              label: 'Transactions',
              link: '/payments/transaction-report',
            },
            {
              label: 'Errors & Declines',
              link: '/payments/errors-and-declines',
            },
            { label: 'Revenues', link: '/payments/revenues' },
            { label: 'Refunds', link: '/payments/refunds-report' },
            // {
            //   label: 'Service Settlements',
            //   link: '/payments/service-settlements',
            //   isVisible: false,
            // },
            // { label: 'Settlements', link: '/payments/settlements' },
            // { label: 'Transfers', link: '/payments/transfers' },
          ],
        },
        { label: 'Refund', link: '/payments/refund' },
        { label: 'Contracts', link: '/payments/contracts' },
        { label: 'Firewall Rules', link: '/payments/firewall-rules' },
        { label: 'Resolution Center', link: '/payments/resolution-center' },
      ],
    },
    {
      label: 'Payouts',
      subMenu: [
        {
          label: 'Reports',
          subMenu: [
            {
              label: 'Transaction Reports',
              link: '/payouts/reports/transaction-reports',
            },
          ],
        },
        { label: 'Balance', link: '/payouts/balance' },
        { label: 'Contracts', link: '/payouts/contracts' },
      ],
    },
    {
      label: 'ID Validation',
      subMenu: [
        {
          label: 'Reports',
          subMenu: [
            {
              label: 'Transaction Reports',
              link: '/id-validation/reports/transaction-report',
            },
            {
              label: 'Service Statements',
              link: '/id-validation/reports/service-statements',
            },
          ],
        },
        { label: 'Contracts', link: '/id-validation/contracts' },
      ],
    },
    { label: 'Logs', link: '/logs' },
    {
      label: 'Merchant Settings',
      subMenu: [
        { label: 'Business Info', link: '/merchant/business-info' },
        { label: 'Bank Accounts', link: '/merchant/bank-accounts' },
        { label: 'API Keys', link: '/merchant/api-keys' },
      ],
    },

    {
      label: 'Legal',
      subMenu: [
        {
          label: 'Merchant Agreement',
          externalLink: 'https://www.epag.com/en/legal/fees/',
          link: '/legal/merchant-agreement',
          external: true,
        },
        {
          label: 'T&Cs',
          externalLink: 'https://www.epag.com/en/legal/tcs/',
          link: '/legal/tcs',
          external: true,
        },
      ],
    },
  ];

  public activeSubMenu: MenuItem | null = null;
  public activeNestedSubMenu: MenuItem | null = null;
  public userData: any;
  private payouts = [];
  private cacheAPI = sessionStorage;

  constructor(
    private sideBarService: SideBarService,
    private router: Router,
    private authService: AuthService,
    private projectsService: ProjectsService
  ) {
    this.theme = environment.theme;
    this.logoWidth = this.theme === 'skin-epag' ? 240 : 440;
    this.basePath =
      this.theme === 'skin-epag' ? '/assets/epag/' : '/assets/letpay/';
    this.checkPayouts();
    this.checkSandboxHml();
  }

  ngOnInit(): void {
    const { selectedItems } = this.sideBarService.loadStateFromLocalStorage();

    this.menuItems = this.menuItems.map((item) => {
      if (item.isVisible === undefined) {
        item.isVisible = true;
      }
      if (item.subMenu && item.subMenu.length > 0) {
        item.subMenu = item.subMenu.map((subItem) => {
          if (subItem.isVisible === undefined) {
            subItem.isVisible = true;
          }
          return subItem;
        });
      }
      return item;
    });

    this.userData = this.authService.userData;

    if (selectedItems[0]) {
      this.selectMenuItem(new Event('click'), selectedItems[0]);
    }
    if (selectedItems[1]) {
      this.selectSubMenuItem(
        new Event('click'),
        selectedItems[0],
        selectedItems[1]
      );
    }
    if (selectedItems[2]) {
      this.selectNestedSubMenuItem(
        new Event('click'),
        selectedItems[0],
        selectedItems[1],
        selectedItems[2]
      );
    }
  }

  public toggleSidebar(): void {
    const sidebar = document.getElementById('sidebar');
    const profileMenu = document.getElementById('profile-menu');

    if (sidebar) {
      sidebar.classList.toggle('minimized');
      this.sideBarService.isMinimized = !this.sideBarService.isMinimized;
      this.minimizedPath =
        this.minimizedPath === 'logos/minimized-logo.svg'
          ? 'logos/logo.svg'
          : 'logos/minimized-logo.svg';
    }
    if (profileMenu) {
      profileMenu.classList.toggle('minimized');
    }
  }

  public toggleMenu(
    event: Event,
    menuItem: MenuItem,
    isNestedMenu: boolean = false
  ): void {
    if (isNestedMenu) {
      if (this.activeNestedSubMenu === menuItem) {
        this.activeNestedSubMenu = null;
        this.nestedMenuMaxHeight = 0;
      } else {
        this.activeNestedSubMenu = menuItem;
        this.nestedMenuMaxHeight = (menuItem.subMenu?.length || 0) * 50;
      }
    } else {
      if (this.activeSubMenu === menuItem) {
        this.activeSubMenu = null;
        this.activeNestedSubMenu = null;
        this.submenuMaxHeight = 0;
      } else {
        this.activeSubMenu = menuItem;
        this.submenuMaxHeight = (menuItem.subMenu?.length || 0) * 50;
      }
    }
  }

  public selectMenuItem(event: Event, menuItem: MenuItem): void {
    event.stopPropagation();
    this.resetSelection();
    this.selectedPath = [menuItem.label];
    this.sideBarService.setItem(0, menuItem);
    if (!menuItem.subMenu) {
      this.activeSubMenu = null;
    }
    this.sideBarService.saveStateToLocalStorage();
    if (menuItem.link) {
      this.router.navigate([menuItem.link]);
    }
  }

  public selectSubMenuItem(
    event: Event,
    menuItem: MenuItem,
    subMenuItem: MenuItem
  ): void {
    event.stopPropagation();
    this.resetSelection(1);
    this.selectedPath = [menuItem.label, subMenuItem.label];
    this.sideBarService.setItem(0, menuItem);
    this.sideBarService.setItem(1, subMenuItem);
    this.checkAndOpenExternalLink(subMenuItem);
    this.sideBarService.saveStateToLocalStorage();

    if (subMenuItem.link) {
      this.router.navigate([subMenuItem.link]);
    }
  }

  public selectNestedSubMenuItem(
    event: Event,
    menuItem: MenuItem,
    subMenuItem: MenuItem,
    nestedSubItem: MenuItem
  ): void {
    event.stopPropagation();
    this.resetSelection(2);
    this.selectedPath = [
      menuItem.label,
      subMenuItem.label,
      nestedSubItem.label,
    ];
    this.sideBarService.setItem(0, menuItem);
    this.sideBarService.setItem(1, subMenuItem);
    this.sideBarService.setItem(2, nestedSubItem);
    this.checkAndOpenExternalLink(nestedSubItem);
    this.sideBarService.saveStateToLocalStorage();
    if (nestedSubItem.link) {
      this.router.navigate([nestedSubItem.link]);
    }
  }

  public logout() {
    this.authService.logout();
  }

  public handleCloseModal() {
    this.openPersonalInfo = false;
    this.openCurrency = false;
  }

  public openPersonalInfoModal() {
    this.openPersonalInfo = true;
  }

  public openCurrencyModal() {
    this.openCurrency = true;
  }

  private resetSelection(level: number = 0): void {
    for (let i = level; i < 3; i++) {
      this.sideBarService.setItem(i, null);
    }
  }

  private checkAndOpenExternalLink(item: MenuItem): void {
    if (item.externalLink) {
      window.open(item.externalLink, '_blank');
    }
  }

  private checkPayouts() {
    let hasPayouts = !!JSON.parse(this.cacheAPI.getItem('hasPayouts') || '[]')
      .length;

    if (!hasPayouts) {
      this.projectsService.getProjects().subscribe(({ contracts }) => {
        this.payouts = contracts.filter(
          (contract: any) => contract.contractType === 'PAYOUT'
        );
        hasPayouts = !!this.payouts.length;
        this.cacheAPI.setItem('hasPayouts', JSON.stringify(this.payouts));
        const payoutsMenuItem = this.menuItems.find(
          (item) => item.label === 'Payouts'
        );
        if (payoutsMenuItem) {
          payoutsMenuItem.isVisible = !!hasPayouts;
        }
      });
    } else {
      const payoutsMenuItem = this.menuItems.find(
        (item) => item.label === 'Payouts'
      );
      if (payoutsMenuItem) {
        payoutsMenuItem.isVisible = !!hasPayouts;
      }
    }
  }

  private checkSandboxHml() {
    const payoutsMenuItem = this.menuItems.filter((item) => {
      return item.showSandboxProd === false;
    });

    if (payoutsMenuItem) {
      payoutsMenuItem.forEach((menu) => {
        menu.isVisible = !!environment.apiURL.includes('hml');
      });
    }
  }
}
